import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

function NotFound() {
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="NotFound">

            {/* Privacy Section ======= */}

            <section id="NotFound" className="NotFound">

            <div className="container" data-aos="fade-up">




        <header class="not-found">
                    <h2>404</h2>
                    <p>Page not found</p>
                    <Link to="/">Go back to Home</Link>
                    </header>

                </div>

            </section>

            {/* End Privacy Section ======= */}

        </motion.div>
    );
}

export default NotFound;
