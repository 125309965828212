// https://github.com/tounsils/vCard/raw/refs/heads/main/ilyes_tounsi.vcf

import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function QRCodeGenerator() {
  const [inputValue, setInputValue] = useState('https://github.com/tounsils/vCard/raw/refs/heads/main/ilyes_tounsi.vcf'); // Default link to your vCard on GitHub

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QR Code Generator</h1>
      <p>Enter the URL to generate your QR code:</p>

      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter URL here"
        style={{ padding: '8px', width: '80%', maxWidth: '500px', marginBottom: '20px' }}
      />

      <div style={{ marginTop: '20px' }}>
        <QRCodeCanvas
          value={inputValue}
          size={256}           // Size of the QR code
          level="H"            // Error correction level
          includeMargin={true} // Adds a margin around QR code
        />
      </div>

      <p style={{ marginTop: '20px', color: '#888' }}>Scan the code to download your vCard.</p>
    </div>
  );
}

export default QRCodeGenerator;
