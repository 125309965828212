import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

import aboutImg from '../../assets/img/about.jpg';

function About() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="about"
        >
            {/* Dynamically set the meta description */}
            <Helmet>
                <title>About us - TN76 Digital: Crafting Seamless Web Experiences.</title>
                <meta name="description" content="Learn about TN76 Digital's mission and vision, driving our commitment to deliver secure and scalable digital solutions." />
            </Helmet>

            {/* About Section ======= */}
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>About Us</h2>
                        <p>Our Mission and Vision</p>
                    </header>
                    
                    <div className="row gy-4">
                        {/* Mission Statement */}
                        <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div className="content">
                                <h3>Mission</h3>
                                <p>
                                    "At TN76, we are committed to enhancing digital experiences through expert website maintenance and cutting-edge web app development."
                                </p>
                                <h3>Vision</h3>
                                <p>
                                    "To be a trusted partner in delivering seamless, secure, and scalable digital solutions."
                                </p>
                                <div className="text-center text-lg-start">
                                    <a href="/#contactus" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Contact us</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Image Section */}
                        <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src={aboutImg} className="img-fluid" alt="About TN76 Digital" />
                        </div>
                    </div>
                </div>
            </section>
            {/* End About Section ======= */}
        </motion.div>
    );
}

export default About;
