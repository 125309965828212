import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

import About from './About';
import Contactus from './Contactus';
import Hero from '../Hero';
import Portfolio from './Portfolio';
import Pricing from './Pricing';
import Services from './Services';
// import EBusinessCard from './EBusinessCardDemo';

function Home() {
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);



    return (

                
            
            
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-page"
        >

                {/* Dynamically set the meta description */}
                <Helmet>
                <title>Home - TN76 Digital: Crafting Seamless Web Experiences.</title>
            </Helmet>


            {/* Hero Section ======= */}
            <Hero />
            {/* End Hero ======= */}

            {/* About Section ======= */}
            <About />
            {/* End About Section ======= */}

            {/* Services Section ======= */}
            <Services />
            {/* End Services Section ======= */}

            {/* portfolio Section ======= */}
            <Portfolio />
            {/* End portfolio Section ======= */}

            {/* Start Pricing Section ======= */}
            <Pricing />
            {/* End Pricing Section ======= */}

            {/* Start Contact Section ======= */}
            <Contactus />
            {/* End Contact Section ======= */}

        </motion.div>

    );
}

export default Home;
