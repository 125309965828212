import React, { useEffect, useState } from 'react';
import { init, send } from '@emailjs/browser'; // Import from the new package
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

// Import necessary Bootstrap CSS in your main CSS or component
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/contact.css';

// Initialize EmailJS with your user ID
init('cpYLenvI-pBltOiF1'); // Replace with your EmailJS user ID

function Contact() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [status, setStatus] = useState(''); // To track email sending status
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        send('service_mhcq7yj', 'template_pak3kne', {
            from_name: formData.name,
            reply_to: formData.email,
            message: formData.message,
        })
            .then((response) => {
                console.log('Email successfully sent!', response.status, response.text);
                setStatus('success'); // Set success message
                setFormData({ name: '', email: '', message: '' }); // Clear form fields
                setShowAlert(true); // Show the alert
                setTimeout(() => setShowAlert(false), 10000); // Auto-dismiss after 10 seconds

            })
            .catch((err) => {
                console.error('Failed to send email. Error:', err);
                setStatus('error'); // Set error message
                setShowAlert(true); // Show the alert
                setTimeout(() => setShowAlert(false), 10000); // Auto-dismiss after 10 seconds

            });
    };

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="contact"
        >

    {/* Dynamically set the meta description */ }
    <Helmet>
        <title>Contact - TN76 Digital: Crafting Seamless Web Experiences.</title>
    </Helmet>
                {/* Start Contact Section ======= */}

            <section id="contactus" class="contactus">

                {showAlert && (
                    <div
                        className={`alert alert-${status === 'success' ? 'success' : 'danger'} alert-dismissible fade show`}
                        role="alert"
                        style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1000 }}
                    >
                        {status === 'success' ? 'Thank you! Your message has been sent successfully.' : 'Error sending your message. Please try again.'}
                        <button type="button" className="btn-close" onClick={() => setShowAlert(false)} aria-label="Close"></button>
                    </div>
                )}

                <header class="section-header">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </header>

                <div className="container contact-container py-5">

                    <div class="row gy-4">

                        <h2 className="text-center mb-4"><i class="bi bi-envelope-at"></i></h2>


                        <form onSubmit={sendEmail} className="contact-form mx-auto">

                            Have questions, comments, or need assistance? We're here to help with any inquiries about our services and resources. Just fill out the form below, and we’ll get back to you within 24-48 hours. Your satisfaction is our priority, and we're always ready to provide the information and support you need.
                            <br /><br />

                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name"
                                    onChange={handleChange}
                                    value={formData.name}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                    onChange={handleChange}
                                    value={formData.email}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <textarea
                                    name="message"
                                    placeholder="Your Message"
                                    onChange={handleChange}
                                    value={formData.message}
                                    required
                                    className="form-control"
                                    rows="4"
                                ></textarea>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary btn-lg">Send Message</button>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
            {/* End Contact Section ======= */}

        </motion.div>

    );
}

export default Contact;
