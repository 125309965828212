import React from 'react';
import { useParams } from 'react-router-dom';

const ECardViewer = () => {
  const { cardName } = useParams();

  return (
    <div>
      <h1>Viewing E-Business Card</h1>
      <p>Card Name: {cardName}</p>
      {/* Add logic to fetch or display the e-business card */}
      <img src={`/demo/e-business-card/${cardName}.png`} alt={cardName} />
    </div>
  );
};

export default ECardViewer;
