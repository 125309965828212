import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import About from './components/pages/About';
import Contactus from './components/pages/Contactus';
import FAQ from './components/pages/FAQ';
import Hero from './components/Hero';
import Home from './components/pages/Home';
import NotFound from './components/NotFound';
import Pricing from './components/pages/Pricing';
import Privacy from './components/pages/Privacy';
import Services from './components/pages/Services';
import Vcard from './components/vCard';
import QRcode from './components/pages/qrcode';
import TermsofService from './components/pages/Termsofservice';
import Portfolio from './components/pages/Portfolio';
import EBusinessCard from './components/pages/EBusinessCardDemo';
import ECardViewer from './components/ECardViewer';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/hero" element={<Hero />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/vcard" element={<Vcard />} />
          <Route path="/qrcode" element={<QRcode />} />
          <Route path="/termsofservice" element={<TermsofService />} />
          <Route path="/ebusinesscard" element={<EBusinessCard />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/demo/e-business-card/:cardName" element={<ECardViewer />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
