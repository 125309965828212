// https://github.com/tounsils/vCard/raw/refs/heads/main/ilyes_tounsi.vcf

import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function QRCodeGenerator() {
  const [inputValue, setInputValue] = useState('https://www.tn76.com'); // Default link to your vCard on GitHub

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);



  return (



    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="home-page">

      {/* Dynamically set the meta description */}
      <Helmet>
        <title>Free QR Code Generator for URL, vCard, and more | Create Your Free QR Codes</title>
        <meta name="viewport" content="initial-scale=1.0,user-scalable=1" />
        <meta charset="utf-8" />
        <meta name="description" content="QR Code Generator for URL, vCard, and more. Add logo, colors, frames, and download in high print quality. Get your free QR Codes now!" />
        <meta name="subtitle" content="Create your QR Code for free" />
        <meta property="og:title" content="Free QR Code Generator for URL, vCard, and more | Create Your Free QR Codes" />
        <meta property="og:description" content="QR Code Generator for URL, vCard, and more. Get your free QR Codes now!" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* qrcode Section ======= */}

      <section id="qrcode" className="qrcode">
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>QR Code Generator</h1>
          <p>Enter the URL to generate your QR code:</p>

          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter URL here"
            style={{ padding: '8px', width: '80%', maxWidth: '500px', marginBottom: '20px' }}
          />

          <div style={{ marginTop: '20px' }}>
            <QRCodeCanvas
              value={inputValue}
              size={256}           // Size of the QR code
              level="H"            // Error correction level
              includeMargin={true} // Adds a margin around QR code
            />
          </div>

          <p style={{ marginTop: '20px', color: '#888' }}>Scan the code</p>
        </div>
      </section>

      {/* End qrcode Section ======= */}

    </motion.div>

  );
}

export default QRCodeGenerator;
