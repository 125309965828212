import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function Services() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="services"
        >
            {/* Dynamically set the meta description */}
            <Helmet>
                <title>Services - TN76 Digital: Crafting Seamless Web Experiences.</title>
                <meta
                    name="description"
                    content="Discover TN76's professional services: Website Maintenance and Web App Development, ensuring secure, optimized websites and scalable, innovative web applications."
                />
            </Helmet>

            {/* Services Section ======= */}
            <section id="services" className="services">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Services</h2>
                        <p>Professional Website Maintenance and Web App Development</p>
                    </header>

                    {/* Section 1: Website Maintenance */}
                    <div className="row gy-4">
                        <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div className="service-box blue">
                                <h3>Website Maintenance</h3>
                                <p>
                                    "We understand how critical your website is. Our maintenance services ensure it's fast, secure, and optimized."
                                </p>
                                <ul>
                                    <li>Regular security checks.</li>
                                    <li>Bug fixes and feature updates.</li>
                                    <li>SEO analysis and implementation.</li>
                                    <li>Database optimization.</li>
                                </ul>
                                <a href="#/" className="read-more"><span>Learn More</span> <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>

                        {/* Section 2: Web App Development */}
                        <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div className="service-box orange">
                                <h3>Web App Development</h3>
                                <p>
                                    "Turn your ideas into reality with our web app solutions."
                                </p>
                                <ul>
                                    <li>Full-cycle development: idea to launch.</li>
                                    <li>Scalable, future-proof designs.</li>
                                    <li>Mobile-first and responsive interfaces.</li>
                                    <li>Post-launch support.</li>
                                </ul>
                                <a href="#/" className="read-more"><span>Learn More</span> <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Services Section ======= */}
        </motion.div>
    );
}

export default Services;
